import { EntityAboutCard, EntityLinksCard } from "@backstage/plugin-catalog";
import { EntityCatalogGraphCard } from "@backstage/plugin-catalog-graph";
import { Grid } from "@material-ui/core";
import React from "react";
import { entityWarningContent } from "./WarningContent";

export const overviewContent = (
    <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6} xs={12}>
            <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6} xs={12}>
            <EntityLinksCard />
        </Grid>
    </ Grid>
);