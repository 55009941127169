import { Grid } from "@material-ui/core";
import { EntityArgoCDHistoryCard, EntityArgoCDOverviewCard } from "@roadiehq/backstage-plugin-argo-cd";
import React from "react";

export const argoCDContent = (
    <Grid container spacing={3} alignItems="stretch">
        < Grid item md={12} xs={12} >
            <EntityArgoCDOverviewCard />
        </Grid >
        <Grid item md={12} xs={12} >
            <EntityArgoCDHistoryCard />
        </Grid>
    </Grid>
);