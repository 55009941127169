import { EntityLayout } from "@backstage/plugin-catalog";
import React from "react";
import { overviewContent } from "../contents/OverviewContent";
import { techdocsContent } from "../contents/TechdocsContent";

export /**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

    const defaultEntityPage = (
        <EntityLayout>
            <EntityLayout.Route path="/" title="Overview">
                {overviewContent}
            </EntityLayout.Route>

            <EntityLayout.Route path="/docs" title="Docs">
                {techdocsContent}
            </EntityLayout.Route>
        </EntityLayout>
    );