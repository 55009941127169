import { EntityKubernetesContent } from "@backstage/plugin-kubernetes";
import { TopologyPage } from "@janus-idp/backstage-plugin-topology";
import { Grid } from "@material-ui/core";
import React from "react";

export const kubernetesContent = (
    <Grid container spacing={3} alignItems="flex-end">
        <Grid item md={12} xs={12}>
            <EntityKubernetesContent refreshIntervalMs={10000} />
        </Grid>
        <Grid item md={12} xs={12}>
            <TopologyPage />
        </Grid>
    </Grid>
);