import React from 'react';
import { makeStyles } from '@material-ui/core';
import logo from './logo/neen.png';

const useStyles = makeStyles({
  png: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <img src={logo} className={classes.png} alt="Brain not only servers..." />
  );
};

export default LogoIcon;
