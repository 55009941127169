import {
    CustomHomepageGrid,
    HomePageCompanyLogo,
    HomePageRandomJoke,
    HomePageRecentlyVisited,
    HomePageStarredEntities,
    HomePageTopVisited
} from '@backstage/plugin-home';
import { HomePageSearchBar } from '@backstage/plugin-search';
import React from 'react';
import LogoFull from '../Root/LogoFull';

export const HomePage = () => (
    <CustomHomepageGrid>
    // Insert the allowed widgets inside the grid
        <HomePageCompanyLogo
            logo={<LogoFull />}
        />
        <HomePageRecentlyVisited />
        <HomePageTopVisited />
        <HomePageSearchBar />
        <HomePageRandomJoke />
        <HomePageStarredEntities />
    </CustomHomepageGrid>
);