import { makeStyles } from '@material-ui/core';
import React from 'react';
import NeenLogoFull from './logo/neen.png';

const useStyles = makeStyles({
  png: {
    display: 'block',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '22%',
    height: 'auto',
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img className={classes.png} src={NeenLogoFull} />;
};

export default LogoFull;
