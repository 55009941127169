import { Grid } from "@material-ui/core";
import React from "react";
import { EntityVaultCard } from "@backstage-community/plugin-vault";

export const vaultContent = (
    <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6} xs={12}>
            <EntityVaultCard />
        </Grid>
    </Grid>
);