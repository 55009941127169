import { EntityGithubActionsContent, isGithubActionsAvailable } from "@backstage-community/plugin-github-actions";
import { EmptyState } from "@backstage/core-components";
import { EntitySwitch } from "@backstage/plugin-catalog";
import { Button } from "@material-ui/core";
import React from "react";

export const cicdContent = (

    // This is an example of how you can implement your company's logic in entity page.
    // You can for example enforce that all components of type 'service' should use GitHubActions
    <EntitySwitch>
        <EntitySwitch.Case if={isGithubActionsAvailable}>
            <EntityGithubActionsContent />
        </EntitySwitch.Case>

        <EntitySwitch.Case if={isGithubActionsAvailable}>
            <EmptyState
                title="No CI/CD available for this entity"
                missing="info"
                description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
                    >
                        Read more
                    </Button>
                }
            />
        </EntitySwitch.Case>
    </EntitySwitch>
); 