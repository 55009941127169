import { isGithubActionsAvailable } from "@backstage-community/plugin-github-actions";
import { EntityDependsOnComponentsCard, EntityDependsOnResourcesCard, EntityLayout } from "@backstage/plugin-catalog";
import { Grid } from "@material-ui/core";
import React from "react";
import { cicdContent } from "../contents/CicdContent";
import { overviewContent } from "../contents/OverviewContent";
import { techdocsContent } from "../contents/TechdocsContent";

export const websiteEntityPage = (
    <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
            {overviewContent}
        </EntityLayout.Route>

        <EntityLayout.Route if={isGithubActionsAvailable} path="/ci-cd" title="CI/CD">
            {cicdContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/dependencies" title="Dependencies">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                    <EntityDependsOnComponentsCard variant="gridItem" />
                </Grid>
                <Grid item md={6}>
                    <EntityDependsOnResourcesCard variant="gridItem" />
                </Grid>
            </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs">
            {techdocsContent}
        </EntityLayout.Route>
    </EntityLayout>
);