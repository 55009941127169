import { EntitySwitch, isComponentType } from "@backstage/plugin-catalog";
import React from "react";
import { defaultEntityPage } from "./DefaultEntityPage";
import { serviceEntityPage } from "./ServiceEntityPage";
import { websiteEntityPage } from "./WebsitePage";

export const componentPage = (
    <EntitySwitch>
        <EntitySwitch.Case>
            {serviceEntityPage}
        </EntitySwitch.Case>

        <EntitySwitch.Case if={isComponentType('website')}>
            {websiteEntityPage}
        </EntitySwitch.Case>

        <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
    </EntitySwitch>
);